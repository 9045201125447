.userInputEllipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}
.WAMuiChipInput-chip-451 {
    margin: 0 8px 10px 0 !important;
}
a{
    color: cornflowerblue;
}
